import { Component, Mixins } from "vue-property-decorator";
import EditorMixin from "./EditorMixin";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class EmergenciesReportTemplate extends Mixins(EditorMixin) {
    render() {
        if (this.form) {
            const formBody = [
                this.beaconSelect(),
                this.channelsSelect(),
                this.intervalTypeSelect(),
                this.intervalSelect()
            ];
            return this.editorBody(formBody);
        }
        return null;
    }

    created() {
        this.form = cloneDeep(
            this.reportTemplate || {
                Id: null,
                ReportType: 5,
                Name: "",
                IntervalType: 1,
                IntervalFromBaseDt: 2,
                ChannelIds: [],
                BeaconIds: [],
                IntervalStartDt: null,
                IntervalEndDt: null
            }
        );
    }
}
