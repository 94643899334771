import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { State } from "vuex-class";
import regexHolder from "@/const/string-validation-regex";
import IChannelMonitoring from "@/contracts/IChannelMonitoring";
import IReportTemplate from "@/contracts/Reports/IReportTemplate";
import cloneDeep from "lodash/cloneDeep";

enum TransactionType {
    ActivationIridium = 1,
    ActivationGSM = 2,
    AbonentPaymentIridium = 3,
    AbonentPaymentGSM = 4,
    PartialAbonentPaymentIridium = 5,
    PartialAbonentPaymentGSM = 6,
    RemoveDeactivationIridium = 7,
    RemoveDeactivationGSM = 8,
    RemoveSuspensionIridium = 9,
    RemoveSuspensionGSM = 10,
    DeactivationIridium = 11,
    DeactivationGSM = 12,
    TrafficOverflowIridium = 13,
    TrafficOverflowGSM = 14,
    SuspendIridium = 15,
    SuspendGSM = 16,
    RefillAccount = 17,
    AbonentPaymentReturnGSM = 18,
    AbonentPaymentReturnIridium = 19
}

@Component
export default class EditorMixin extends Vue {
    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    @Prop()
    reportTemplate!: IReportTemplate;

    created() {
        this.$root.$on("report:saveTemplate", this.handleSave);
        this.$root.$on("report:loadTemplate", this.handleLoad);
    }

    get channels(): IChannelMonitoring[] {
        const beacon = this.beacons.find(beacon => beacon.Id === this.beaconId);
        if (beacon) {
            return beacon.Channels;
        }
        return [];
    }

    get beaconId() {
        if (this.form.BeaconIds && this.form.BeaconIds.length > 0) {
            return this.form.BeaconIds[0];
        }
    }

    set beaconId(value) {
        this.form.BeaconIds = [value];
    }

    get period() {
        return this.form.IntervalStartDt && this.form.IntervalEndDt
            ? [this.form.IntervalStartDt, this.form.IntervalEndDt]
            : "";
    }

    set period(value) {
        if (Array.isArray(value) && value.length === 2) {
            this.form.IntervalStartDt = value[0];
            this.form.IntervalEndDt = value[1];
        }
    }

    protected form: any = {};

    protected minimumDaysInterval: number = 1;
    protected maximumDaysInterval: number = 6900;

    protected async handleLoad(template) {
        this.form = cloneDeep(template);
    }

    protected async handleSave() {
        try {
            await (this.$refs.form as any).validate();
            this.$emit("save", this.form);
        } catch (error) {}
    }

    protected async handleBuild() {
        try {
            await (this.$refs.form as any).validate();
            this.$emit("build", this.form);
        } catch (error) {}
    }

    protected editorBody(formBody) {
        return (
            <div class="d-flex flex-column h-100 w-100">
                <div class="scroll mb-3">
                    <el-form size="medium" ref="form" attrs={{ model: this.form }} show-message={false}>
                        {formBody}
                    </el-form>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <el-button round size="medium" type="primary" class="mr-2 w-50" onClick={this.handleBuild}>
                        {this.$t("reports.editor.buildWithoutSaving")}
                    </el-button>
                </div>
            </div>
        );
    }

    protected nameInput() {
        return (
            <div>
                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.editor.templateName")}</div>
                <el-form-item
                    prop="Name"
                    rules={[
                        {
                            required: true,
                            pattern: regexHolder["settings_name"].value,
                            message: "",
                            trigger: "blur"
                        }
                    ]}
                >
                    <el-input vModel={this.form.Name} maxlength={100} />
                </el-form-item>
            </div>
        );
    }

    protected beaconsSelect() {
        return (
            <div>
                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.editor.object")}</div>
                <el-form-item
                    prop="BeaconIds"
                    rules={[
                        {
                            type: "array",
                            min: 1,
                            required: true,
                            message: "",
                            trigger: "change"
                        }
                    ]}
                >
                    <el-select vModel={this.form.BeaconIds} multiple filterable>
                        {this.beacons.map(beacon => (
                            <el-option key={beacon.Id} label={beacon.Name} value={beacon.Id}>
                                <div class="d-flex align-items-center">
                                    <div
                                        style={{
                                            backgroundColor: `${beacon.UserInterfaceSettings.Color}22`
                                        }}
                                        class="rounded-circle mr-2"
                                    >
                                        <icon-base
                                            iconHeight={24}
                                            iconWidth={24}
                                            iconName={beacon.IconPath}
                                            iconColor={beacon.UserInterfaceSettings.Color}
                                        />
                                    </div>
                                    <div>{beacon.Name}</div>
                                </div>
                            </el-option>
                        ))}
                    </el-select>
                </el-form-item>
            </div>
        );
    }

    protected beaconSelect() {
        return (
            <div>
                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.editor.object")}</div>
                <el-form-item
                    prop="BeaconIds"
                    rules={[
                        {
                            type: "array",
                            min: 1,
                            required: true,
                            message: "",
                            trigger: "change"
                        }
                    ]}
                >
                    <el-select vModel={this.beaconId} filterable>
                        {this.beacons.map(beacon => (
                            <el-option key={beacon.Id} label={beacon.Name} value={beacon.Id}>
                                <div class="d-flex align-items-center">
                                    <div
                                        style={{
                                            backgroundColor: `${beacon.UserInterfaceSettings.Color}22`
                                        }}
                                        class="rounded-circle mr-2"
                                    >
                                        <icon-base
                                            iconHeight={24}
                                            iconWidth={24}
                                            iconName={beacon.IconPath}
                                            iconColor={beacon.UserInterfaceSettings.Color}
                                        />
                                    </div>
                                    <div>{beacon.Name}</div>
                                </div>
                            </el-option>
                        ))}
                    </el-select>
                </el-form-item>
            </div>
        );
    }

    protected channelsSelect() {
        return (
            <div>
                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.editor.sensors")}</div>
                <el-form-item
                    prop="ChannelIds"
                    rules={[
                        {
                            type: "array",
                            required: true,
                            min: 1,
                            message: "",
                            trigger: "change"
                        }
                    ]}
                >
                    <el-select vModel={this.form.ChannelIds} multiple filterable>
                        {this.channels.map(channel => (
                            <el-option key={channel.Id} label={channel.Name} value={channel.Id} />
                        ))}
                    </el-select>
                </el-form-item>
            </div>
        );
    }

    protected intervalTypeSelect() {
        return (
            <div>
                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.editor.interval")}</div>
                <el-form-item>
                    <el-radio-group class="d-flex equal-width-radio-button" vModel={this.form.IntervalType}>
                        <el-radio-button class="d-flex w-50" label={1}>
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fad fa-history fa-2x mb-2" />
                                {this.$t("reports.editor.daysInterval")}
                            </div>
                        </el-radio-button>
                        <el-radio-button class="d-flex w-50" label={2}>
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fad fa-2x fa-calendar-alt mb-2" />
                                {this.$t("reports.editor.fixedInterval")}
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
        );
    }

    protected intervalSelect() {
        return this.form.IntervalType === 2 ? (
            <el-form-item>
                <el-date-picker
                    class="w-100"
                    vModel={this.period}
                    clearable={false}
                    format="dd.MM.yy HH:mm"
                    type="datetimerange"
                    picker-options={{
                        firstDayOfWeek: 1
                    }}
                    range-separator="-"
                    unlink-panels
                    start-placeholder={this.$t("datepicker.from")}
                    end-placeholder={this.$t("datepicker.to")}
                    align="left"
                />
            </el-form-item>
        ) : this.form.IntervalType === 1 ? (
            <el-form-item>
                <div class="d-flex flex-column">
                    <el-input-number
                        class="mb-3 w-100"
                        min={this.minimumDaysInterval}
                        max={this.maximumDaysInterval}
                        vModel={this.form.IntervalFromBaseDt}
                    />
                    <el-button-group>
                        <el-button onClick={this.setIntervalFromBaseDt2Days}>
                            {this.$t("reportIntervals.twoDays")}
                        </el-button>
                        <el-button onClick={this.setIntervalFromBaseDtWeek}>
                            {this.$t("reportIntervals.week")}
                        </el-button>
                        <el-button onClick={this.setIntervalFromBaseDtMonth}>
                            {this.$t("reportIntervals.month")}
                        </el-button>
                    </el-button-group>
                </div>
            </el-form-item>
        ) : null;
    }

    private setIntervalFromBaseDt2Days() {
        this.form.IntervalFromBaseDt = 2;
    }

    private setIntervalFromBaseDtWeek() {
        this.form.IntervalFromBaseDt = 7;
    }

    private setIntervalFromBaseDtMonth() {
        this.form.IntervalFromBaseDt = 30;
    }

    protected transactionTypesSelect() {
        return (
            <div>
                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.editor.transactions")}</div>
                <el-form-item
                    prop="TransactionTypes"
                    rules={[
                        {
                            type: "array",
                            required: true,
                            message: "",
                            trigger: "change"
                        }
                    ]}
                >
                    <el-select vModel={this.form.TransactionTypes} multiple filterable>
                        {Object.keys(TransactionType)
                            .filter(key => isNaN(Number(TransactionType[key])))
                            .map(type => (
                                <el-option
                                    key={type}
                                    label={this.$t(`enums.TransactionType.${TransactionType[type]}`)}
                                    value={TransactionType[type]}
                                />
                            ))}
                    </el-select>
                </el-form-item>
            </div>
        );
    }
}
