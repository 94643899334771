export default [
    // {
    //     key: "beaconsReport",
    //     icon: "fas fa-table"
    // },
    // 2: {
    //   key: 'channelsReport',
    // },
    {
        id: 3,
        key: "beaconDataReport",
        icon: "fas fa-map-marker-alt"
    },
    {
        id: 4,
        key: "channelDataReport",
        icon: "fas fa-tachometer"
    },
    {
        id: 5,
        key: "emergenciesReport",
        icon: "fas fa-exclamation-triangle"
    },
    {
        id: 6,
        key: "beaconsDataPacketsReport",
        icon: "fas fa-database"
    }
    // 7: {
    //     key: "transactionsReport",
    //     icon: "fas fa-database"
    // }
];
