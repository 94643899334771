import { Vue, Component, Prop } from "vue-property-decorator";

import BeaconsReportTemplate from "./EditorTemplates/BeaconsReportTemplate";
import BeaconDataReportTemplate from "./EditorTemplates/BeaconDataReportTemplate";
import ChannelDataReportTemplate from "./EditorTemplates/ChannelDataReportTemplate";
import EmergenciesReportTemplate from "./EditorTemplates/EmergenciesReportTemplate";
import BeaconDataPacketsReportTemplate from "./EditorTemplates/BeaconDataPacketsReportTemplate";
import TransactionsReportTemplate from "./EditorTemplates/TransactionsReportTemplate";
import IReportTemplate from "@/contracts/IReportTemplate";

@Component({
    components: {
        BeaconsReportTemplate,
        BeaconDataReportTemplate,
        ChannelDataReportTemplate,
        EmergenciesReportTemplate,
        BeaconDataPacketsReportTemplate,
        TransactionsReportTemplate
    }
})
export default class ReportSettingsEditor extends Vue {
    render() {
        return <div class="d-flex flex-column h-100">{this.currentTemplate}</div>;
    }

    @Prop()
    reportTemplate!: IReportTemplate;

    @Prop()
    selectedReport!: number;

    get currentTemplate() {
        let Component: any = null;
        switch (this.selectedReport) {
            case 1:
                Component = BeaconsReportTemplate;
                break;
            case 3:
                Component = BeaconDataReportTemplate;
                break;
            case 4:
                Component = ChannelDataReportTemplate;
                break;
            case 5:
                Component = EmergenciesReportTemplate;
                break;
            case 6:
                Component = BeaconDataPacketsReportTemplate;
                break;
            case 7:
                Component = TransactionsReportTemplate;
                break;
            default:
                Component = null;
                break;
        }
        if (Component) {
            return (
                <Component
                    reportTemplate={this.reportTemplate}
                    onBuild={form => this.$emit("build", form)}
                    onSave={form => this.$emit("save", form)}
                />
            );
        } else {
            return null;
        }
    }
}
