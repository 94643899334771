import { Component, Mixins } from "vue-property-decorator";
import EditorMixin from "./EditorMixin";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class BeaconDataPacketsReportTemplate extends Mixins(EditorMixin) {
    render() {
        if (this.form) {
            const formBody = [this.beaconsSelect(), this.intervalTypeSelect(), this.intervalSelect()];
            return this.editorBody(formBody);
        }
        return null;
    }

    created() {
        this.form = cloneDeep(
            this.reportTemplate || {
                Id: null,
                ReportType: 6,
                Name: "",
                IntervalType: 1,
                IntervalFromBaseDt: 2,
                BeaconIds: [],
                IntervalStartDt: null,
                IntervalEndDt: null
            }
        );
    }
}
