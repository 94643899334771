import { Vue, Component, Prop } from "vue-property-decorator";
import IReportTemplate from "@/contracts/IReportTemplate";
import dayjs from "dayjs";
import reportTypes from "@/const/report-types";
import "./ReportTemplateCard.scss";

import Card from "@/components/Containers/Card";
import { State } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

@Component({
    components: {
        Card
    }
})
export default class ReportTemplateCard extends Vue {
    render() {
        let intervalViewer: any = null;
        if (this.reportTemplate.IntervalType === 2) {
            intervalViewer = (
                <div class="p-1">
                    <date-wrapper date={this.reportTemplate.IntervalStartDt} />
                    <span class="mx-1">-</span>
                    <date-wrapper date={this.reportTemplate.IntervalEndDt} />
                </div>
            );
        } else if (this.reportTemplate.IntervalType === 1) {
            intervalViewer = (
                <div class="p-1">
                    {this.$tc("reports.viewer.daysBeforeCurrent", this.reportTemplate.IntervalFromBaseDt, {
                        days: this.reportTemplate.IntervalFromBaseDt
                    })}
                </div>
            );
        } else if (this.reportTemplate.IntervalType === 0) {
            intervalViewer = <div class="p-1">{this.$t("reports.viewer.noInterval")}</div>;
        }

        return (
            <card selected={this.selected} onSelect={() => this.$emit("select")}>
                <div class="d-flex align-items-center" style="min-width: 0;" slot="headerLeft">
                    <div class="font-weight-medium font-size-s text-truncate text-left flex-grow-1">
                        {this.reportTemplate.Name}
                    </div>
                </div>
                <div class="d-flex" slot="headerRight">
                    <el-button
                        onClick={this.handleDelete}
                        class="lo-card-button report-template-card__button m-0 flex-shrink-0"
                        icon="fas fa-trash"
                    />
                </div>
                <div slot="footerLeft" class="d-flex flex-column align-items-start">
                    <div class="d-flex flex-wrap">
                        {this.reportType && (
                            <el-tag class="mr-1 mb-2" size="small">
                                <span class="mr-1 font-weight-medium">{this.$t("default.reportType")}:</span>
                                <span class="text-muted">{this.$t(`enums.reporttypes.${this.reportType.key}`)}</span>
                            </el-tag>
                        )}
                        {this.reportTemplate.BeaconIds && this.reportTemplate.BeaconIds.length > 0 && this.beaconsTags}
                        {this.reportTemplate.ChannelIds &&
                            this.reportTemplate.ChannelIds.length > 0 &&
                            this.channelsTags}
                    </div>
                    <div class="report-template-card__interval text-muted">{intervalViewer}</div>
                </div>
            </card>
        );
    }

    @Prop()
    reportTemplate!: IReportTemplate;

    @Prop()
    selected!: boolean;

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    get intervalEndDt() {
        if (this.reportTemplate.IntervalType === 1) {
            return dayjs();
        }
    }

    get intervalStartDt() {
        if (this.reportTemplate.IntervalType === 1) {
            return dayjs().subtract(this.reportTemplate.IntervalFromBaseDt, "day");
        }
    }

    get channelsTags() {
        if (this.reportTemplate.ChannelIds.length > 0 && this.reportTemplate.BeaconIds.length > 0) {
            const beacon = this.beacons.find(beacon => beacon.Id === this.reportTemplate.BeaconIds[0]);
            if (beacon) {
                return this.reportTemplate.ChannelIds.map(x => {
                    const channel = beacon.Channels.find(channel => channel.Id === x);
                    if (channel) {
                        return (
                            <el-tag class="mr-1 mb-2" size="small">
                                <span class="mr-1 font-weight-medium">{this.$t("default.sensor")}:</span>
                                <span class="text-muted">{channel.Name}</span>
                            </el-tag>
                        );
                    }
                    return null;
                });
            }
        }

        return null;
    }

    get beaconsTags() {
        return this.reportTemplate.BeaconIds.map(x => {
            const beacon = this.beacons.find(beacon => beacon.Id === x);
            if (beacon) {
                return (
                    <el-tag class="mr-1 mb-2" size="small">
                        <span class="mr-1 font-weight-medium"> {this.$t("default.object")}:</span>
                        <span class="text-muted">{beacon.Name}</span>
                    </el-tag>
                );
            }
            return null;
        });
    }

    get reportType() {
        return reportTypes.find(x => this.reportTemplate.ReportType === x.id);
    }

    handleDelete(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.$emit("delete");
    }
}
