import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "CommonReportTemplate"
})
class CommonReportTemplate extends Vue {
    render() {
        if (!this.reportData) {
            return null;
        }

        return (
            <div class="w-100">
                <div class="d-flex">
                    <h5 class="font-weight-medium">{this.reportData.Header}</h5>
                </div>
                {this.reportData.AdditionalInfo.length > 0 && (
                    <div class="d-flex">
                        {this.reportData.AdditionalInfo.map((additionalInfo, index) => (
                            <el-tag class="mr-1 mb-2">
                                <span class="mr-1 font-weight-medium">{additionalInfo.Description}:</span>
                                <span class="text-muted">{additionalInfo.Value}</span>
                            </el-tag>
                        ))}
                    </div>
                )}
                <div>
                    {this.reportData.ReportItems ? (
                        this.reportData.ReportItems.map((reportItem, index) => (
                            <common-report-template key={index} reportData={reportItem} />
                        ))
                    ) : (
                        <el-table class="mb-3" stripe border data={this.reportData.Data}>
                            {this.reportData.Columns.map(column => (
                                <el-table-column align="left" prop={column.ColumnKey} label={column.Label} />
                            ))}
                        </el-table>
                    )}
                </div>
            </div>
        );
    }

    @Prop() reportData: any;
}

export default CommonReportTemplate;
