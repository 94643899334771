export default [
    {
        value: 2,
        key: "html"
    },
    {
        value: 3,
        key: "xlsx"
    }
];
