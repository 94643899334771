import { Component, Mixins } from "vue-property-decorator";
import EditorMixin from "./EditorMixin";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class BeaconsReportTemplate extends Mixins(EditorMixin) {
    render() {
        if (this.form) {
            const formBody = [this.beaconsSelect()];
            return this.editorBody(formBody);
        }
        return null;
    }

    created() {
        this.form = cloneDeep(
            this.reportTemplate || {
                Id: null,
                ReportType: 1,
                Name: "",
                IntervalType: 0,
                IntervalFromBaseDt: 2,
                BeaconIds: [],
                IntervalStartDt: null,
                IntervalEndDt: null
            }
        );
    }
}
