import CommonReportTemplate from "./ReportTemplates/CommonReportTemplate";
import exportTypes from "@/const/export-types";
import Empty from "@/components/Core/Empty";
import { Vue, Component, Prop } from "vue-property-decorator";

import "./ReportViewer.scss";

@Component({
    components: {
        CommonReportTemplate,
        Empty
    }
})
export default class ReportViewer extends Vue {
    render() {
        return this.reportData ? (
            <div class="report-viewer scroll p-3">
                <div class="d-flex justify-content-end align-items-center" style="min-height: 70px;">
                    {exportTypes.map(exportType => (
                        <el-button size="small" type="primary" onClick={() => this.handleExport(exportType)}>
                            {this.$t("enums.exportTypes." + exportType.key)}
                        </el-button>
                    ))}
                </div>
                <div class="d-flex w-100">
                    <common-report-template reportData={this.reportData} />
                </div>
            </div>
        ) : (
            <div class="ReportViewerEmpty">
                <empty text={this.$t("default.nodata")} icon="fad fa-file-chart-pie fa-5x"></empty>
            </div>
        );
    }

    @Prop()
    reportData!: any;

    handleExport(value) {
        this.$emit("export", value.value);
    }
}
