import "./ReportCard.scss";

import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ReportCard extends Vue {
    @Prop()
    selected!: boolean;

    @Prop()
    reportCard;

    render() {
        return (
            <div class={[this.selected && "selected", "report-card"]}>
                <div class="flex-shrink-0 report-card_icon">
                    <i class={this.reportCard.icon} />
                </div>
                <span class="text-truncate text-left ml-2 font-size-s">
                    {this.$t(`enums.reporttypes.${this.reportCard.key}`)}
                </span>
            </div>
        );
    }
}
