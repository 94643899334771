import { Vue, Component, Prop } from "vue-property-decorator";
import "./Card.scss";

@Component
export default class Card extends Vue {
    @Prop()
    selected!: boolean;

    render() {
        return (
            <div class={[this.selected && "card_selected", "card"]} onClick={() => this.$emit("select")}>
                <div class="card__body">
                    <div class="card__header">
                        <div class="card__left-side">{this.$slots.headerLeft}</div>
                        <div class="card__right-side">{this.$slots.headerRight}</div>
                    </div>
                    <div class="card__footer">
                        <div class="card__left-side">{this.$slots.footerLeft}</div>
                        <div class="card__right-side">{this.$slots.footerRight}</div>
                    </div>
                </div>
            </div>
        );
    }
}
