import ReportCard from "@/components/Reports/ReportCard";
import ReportTemplateCard from "@/components/Reports/ReportTemplateCard";
import ReportSettingsEditor from "@/components/Reports/ReportSettingsEditor";
import ReportViewer from "@/components/Reports/ReportViewer";

import reportTypes from "@/const/report-types";

import { MessageBox } from "element-ui";

import {
    loadReportTemplates,
    createReportTemplate,
    deleteReportTemplate,
    buildReport,
    exportReport
} from "@/api/report";

import messages from "@/components/Mixins/messages";

import { Component, Mixins } from "vue-property-decorator";

import IReportTemplate from "@/contracts/IReportTemplate";

import "./ReportsView.scss";

@Component({
    components: {
        ReportCard,
        ReportTemplateCard,
        ReportSettingsEditor,
        ReportViewer
    }
})
export default class ReportsView extends Mixins(messages) {
    render() {
        return (
            <div class="canvas">
                <div class="h-100 reports-view p-0 d-flex" style="overflow-x: auto; overflow-y: hidden;">
                    <div class="d-flex w-100 h-100">
                        <div class="p-3 reports-view_sidebar">
                            <el-dialog
                                class="reports-templates-dialog"
                                title={this.$t("default.reportTemplates")}
                                visible={this.dialogVisible}
                                width="30%"
                                onClose={() => (this.dialogVisible = false)}
                            >
                                {this.reportTemplates.map(reportTemplate => (
                                    <report-template-card
                                        reportTemplate={reportTemplate}
                                        key={reportTemplate.Id}
                                        onSelect={() => this.selectReportTemplate(reportTemplate)}
                                        onDelete={() => this.deleteReportTemplate(reportTemplate)}
                                    />
                                ))}
                            </el-dialog>
                            <div class="d-flex align-items-center justify-content-between" style="min-height: 70px;">
                                <h5 class="mb-0">{this.$t("default.settings")}</h5>
                                <div>
                                    <el-button size="small" type="primary" onClick={() => (this.dialogVisible = true)}>
                                        {this.$t("reports.loadTemplate")}
                                    </el-button>
                                    <el-button size="small" type="primary" onClick={this.saveTemplate}>
                                        {this.$t("reports.saveTemplate")}
                                    </el-button>
                                </div>
                            </div>
                            <div class="font-weight-medium text-left w-100 mb-2">{this.$t("reports.type")}</div>
                            <el-select
                                vModel={this.selectedReportType}
                                filterable
                                size="medium"
                                style="margin-bottom: 1rem;"
                            >
                                {reportTypes.map(reportCard => (
                                    <el-option
                                        key={reportCard.key}
                                        label={this.$t(`enums.reporttypes.${reportCard.key}`)}
                                        value={reportCard.id}
                                    >
                                        <report-card reportCard={reportCard} key={reportCard.key} />
                                    </el-option>
                                ))}
                            </el-select>
                            <report-settings-editor
                                selectedReport={this.selectedReportType}
                                reportTemplate={this.selectedReportTemplate}
                                onBuild={this.buildReportTemplate}
                                onSave={this.saveReportTemplate}
                                onFormChanged={() => (this.selectedReportTemplate = null)}
                            />
                        </div>
                        <report-viewer onExport={this.handleExport} reportData={this.reportData} />
                    </div>
                </div>
            </div>
        );
    }

    private dialogVisible: boolean = false;
    private selectedReportType: number = reportTypes[0].id;
    private reportTemplates: IReportTemplate[] = [];
    private reportData: any = null;
    private notSavedTemplate: IReportTemplate | null = null;
    private selectedReportTemplate: IReportTemplate | null = null;

    private saveTemplate() {
        this.$root.$emit("report:saveTemplate");
    }

    private async handleExport(format) {
        if (this.notSavedTemplate) {
            MessageBox.prompt(this.$t("default.enterReportName").toString(), this.$t("default.reportName").toString(), {
                confirmButtonText: this.$t("default.save").toString(),
                cancelButtonText: this.$t("default.cancel").toString(),
                inputType: "text"
            })
                .then(async ({ value }: any) => {
                    if (this.notSavedTemplate) {
                        this.notSavedTemplate.Name = value;
                        const data = await exportReport(this.notSavedTemplate, format);
                        if (!data) {
                            return;
                        }
                        const url = window.URL.createObjectURL(
                            new Blob([data], {
                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            })
                        );

                        let extension: string;
                        switch (format) {
                            case 2:
                                extension = "html";
                                break;
                            case 3:
                                extension = "xlsx";
                                break;
                            default:
                                extension = "html";
                                break;
                        }

                        let element = document.createElement("a");
                        element.href = url;
                        element.download = `${this.notSavedTemplate.Name || "report"}.${extension}`;
                        element.style.display = "none";
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                    }
                })
                .catch(() => {});
        }
    }

    private selectReportTemplate(reportTemplate: IReportTemplate) {
        this.selectedReportType = reportTemplate.ReportType;
        this.selectedReportTemplate = reportTemplate;

        this.$root.$emit("report:loadTemplate", { ...reportTemplate });

        this.dialogVisible = false;
    }

    private async saveReportTemplate(template: IReportTemplate) {
        MessageBox.prompt(this.$t("default.enterReportName").toString(), this.$t("default.reportName").toString(), {
            confirmButtonText: "Сохранить",
            cancelButtonText: "Отмена",
            inputType: "text"
        })
            .then(async ({ value }: any) => {
                template.Name = value;
                let createdTemplate = await createReportTemplate(template);
                if (createdTemplate) {
                    this.reportTemplates.push(createdTemplate);
                }
                this.operationSuccess();
            })
            .catch(() => {});
    }

    private async deleteReportTemplate(template) {
        try {
            await this.$confirm(this.$t("default.confirmDelete", { name: template.Name }).toString(), "", {
                confirmButtonText: this.$t("default.ok").toString(),
                cancelButtonText: this.$t("default.cancel").toString(),
                type: "warning",
                center: true,
                showClose: false
            });
            await deleteReportTemplate(template.Id);

            this.reportTemplates = this.reportTemplates.filter(t => t.Id !== template.Id);
            this.operationSuccess();
        } catch (error) {}
    }

    private async loadReportTemplates() {
        this.reportTemplates = await loadReportTemplates();
    }

    private async buildReportTemplate(form) {
        this.reportData = await buildReport(form);
        this.notSavedTemplate = form;
    }

    created() {
        this.loadReportTemplates();
    }
}
